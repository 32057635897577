<template>
  <div class="mt-4  px-5">
    <h2>Auswertung</h2>

    <v-card
        class="mt-4"
        elevation="5">
      <v-card-title>
        Anzahl Einträge pro Monat
      </v-card-title>
      <column-chart :data="comp_ShootingBookCount"></column-chart>
    </v-card>
    <v-divider></v-divider>


    <v-card
        class="mt-4"
        elevation="5">
      <v-card-title>
        Ergebnis Verlauf pro Waffe
      </v-card-title>
        <v-container
        id="Datum_auswahl">
          <v-row no-gutters>
            <v-col
                cols="2"
                sm="2">
              <v-menu
                  ref="Picker_von"
                  v-model="Picker_von"
                  :close-on-content-click="false"
                  :return-value.sync="date_von"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date_von"
                      label="Datum von"
                      class="mx-2"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date_von"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="Picker_von = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.Picker_von.save(date_von)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
                cols="2"
                sm="2">
              <v-menu
                  ref="Picker_bis"
                  v-model="Picker_bis"
                  :close-on-content-click="false"
                  :return-value.sync="date_bis"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="date_bis"
                      label="Datum bis"
                      prepend-icon="mdi-calendar"
                      readonly
                      class="mx-2"
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date_bis"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="Picker_bis = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.Picker_bis.save(date_bis)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
                cols="6"
                sm="4">
              <v-select
                  v-model="OwnWeapon"
                  :items="comp_OpenShootingBookData_OwnWeapons"
                  item-text="Waffe"
                  item-value="id"
                  label="Waffe"
                  offset-y
                  value=""
                  return-object
                  class="mx-2"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.KurzT }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.KurzT }}
                </template>

              </v-select>
            </v-col>
             <v-col
                cols="2"
                sm="2">
              <v-btn
                  class="ml-5 mt-2 primary text--white"
                  text
                  @click="get_charts()"
              >
                OK
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      <line-chart :data="comp_ChartData"></line-chart>
    </v-card>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: 'analytics',
  data: () => ({
    dialog: false,
    OwnWeapon: null,
    // date_von: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date_von:  moment().subtract(3, 'months').format('YYYY-MM-DD'),
    // date_bis: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date_bis: moment().format('YYYY-MM-DD'),
    Picker_von: false,
    Picker_bis: false
  }),

  computed: {
    comp_OpenShootingBookData_OwnWeapons() {
      return this.$store.state.UserApp.Weapons;
    },
    comp_ChartData() {
      const Data = this.$store.state.UserApp.Charts;
      let ChartData = {};
      if (Data !== null) {
        Data.Analytics.forEach(i => {
          ChartData[i.Datum] = i.GesamtErgebnis;
        })
      }
      return ChartData;
    },
    comp_ShootingBookCount() {
      const Data = this.$store.state.UserApp.shootingbook_Count;
      let ChartData = {};
      if (Data !== null) {
        Data.forEach(i => {
          ChartData[i.Monat + ' ' + i.Year] = i.Anzahl;
        })
      }
      return ChartData;
    },
  },
  mounted() {
    this.initData();
    this.get_charts();
  },
  methods: {
    initData() {
      this.OwnWeapon = this.$store.state.UserApp.Weapons[0];
    },
    get_charts() {
      if (this.OwnWeapon) {

        var Data = {
          chartWeaponID: this.OwnWeapon.id,
          inputDatevon: this.date_von,
          inputDatebis: this.date_bis
        }
        this.$store.dispatch('UserApp/getChartsData', Data)
            .then()
      }
    },
  },
}
</script>

<style scoped>
.small {
  max-width: 600px;
  margin: 150px auto;
}
#Datum_auswahl{
  margin-left: 0px;
}
</style>